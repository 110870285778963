*{
    box-sizing: border-box;
} 
body{
    background-color: black;
}
.close-in-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}

.close-in-underline::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust the height to your preference */
    bottom: 0;
    left: 0;
    background-color: currentColor; /* Uses the text color for the underline */
    transform: scaleX(1);
    transform-origin: center; /* Makes the line close in from both ends */
    transition: transform 0.5s ease; /* Smooth transition */
}

.close-in-underline:hover::after {
    transform: scaleX(0.25); /* Shrinks the underline to 0 on hover */
}
